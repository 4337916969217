window.$ = window.jQuery = require('jquery'); 
require('./bootstrap');

window.toggleNotification = function() {
      
    $.ajax({
    type: "GET",
    url: "togglenotification", 
    success: ajaxOnToggle 
    });
     
};

function ajaxOnToggle(resp)
{
    if(resp.notification==0)
    {
        $("#inotif").removeClass("fa-toggle-on").addClass("fa-toggle-off");
    }
    else
    {
        $("#inotif").removeClass("fa-toggle-off").addClass("fa-toggle-on");
    } 
}